import React from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchInputProps {
  placeholder: string;
  onChange?: any;
  withButton?: boolean;
  onClick?: any;
}

const SearchInput: React.FC<SearchInputProps> = props => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (!!props.onClick) {
          props.onClick();
        }
      }}
    >
      <label htmlFor="site-search" className="sr-only">
        Search
      </label>
      <input
        name="site-search"
        className="w-full p-4 px-4 py-3 bg-gray-200 rounded-full focus:outline-none sans-serif"
        placeholder={props.placeholder}
        onChange={props.onChange}
        id="site-search"
      />
      {props.withButton && (
        <button
          className="px-3 py-2 -ml-12 rounded-full shadow-md text-light_green bg-primary sans-serif focus:outline-none"
          type="submit"
        >
          <span className="sr-only">Submit</span>
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </button>
      )}
    </form>
  );
};

export default SearchInput;
